<template>
    <div>
      <div>
        <el-row class="bgW">
        <el-col :span="20">
          <!--检索条件-->
          <el-form class="search" :inline="true" label-suffix="：" size="mini" :model="search">
  
             <el-form-item label="日期">
              <el-date-picker
                v-model="search.start_time"
                type="date"
                class="w130"
                placeholder="开始日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
              <el-date-picker
                v-model="search.end_time"
                type="date"
                class="w130"
                placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="门店类型" prop="type">
              <el-select
                v-model="search.type"
                class="w100"
                clearable
                placeholder="门诊"
              >
                <el-option
                  v-for="item in type"
                  :key="item.shop_id"
                  :label="item.shop_name"
                  :value="item.shop_id"
                />
              </el-select>
            </el-form-item>
  
          </el-form>
        </el-col>
        <el-col :span="4" class="btnBox">
          <el-button type="primary" icon="el-icon-search" size="mini" @click="getList('search')">搜索</el-button>
          <!-- <el-button type="primary" class="mb10" size="mini"  :loading="exportS" @click="exportExcel">导出数据</el-button> -->
        </el-col>
      </el-row>
  
        <!--列表-->
        <el-row>
          <el-col :span="24">
            <el-table
              v-if="showTable"
              :data="tableData"
              size="small"
              max-height="750"
              border
              v-loading="loading"
              :span-method="objectSpanMethod"
              :cell-style="cellStyle"
              :header-cell-style="rowClass"
            > 
          
              <ElTableColumn label="市场" prop="market_name"  label-class-name="market"/>
              <ElTableColumn label="门店" prop="shop_name" />
              <ElTableColumn label="员工姓名" prop="name" />
              <ElTableColumn label="岗位" prop="station_name" />
              <template v-if="is_show_doctor">
                <ElTableColumn label="医师数据" prop="physicianCount"  @click="clickOnline(row,'physicianCount')">
                  <template slot-scope="{ row }">
                    <div class="hand"@click="clickOnline(row,'physicianCount')">{{row.physicianCount}}</div>
                  </template>
                </ElTableColumn>
              </template>

              <ElTableColumn label="跟台护士数据">
                <el-table-column v-for="(item, index) in title" :key="index"  :label="item.label"  >
                   <template slot-scope="{ row }">
                    <div class="hand" @click="clickOnline(row,row.title[index].label)">{{row.title[index].value}}</div>
                  </template>
                </el-table-column>
              </ElTableColumn>
              <ElTableColumn label="配台费" v-if="is_show_setUpfee">
              <el-table-column v-if ="is_show_setUpfee" v-for="(item, index) in setUpFee" :key="index"  :label="item.label">
                 <template slot-scope="{ row }">
                  <div class="hand" @click="clickOnline(row,row.setUpFee[index].label)">{{row.setUpFee[index].value}}</div>
                </template>
              </el-table-column>
            </ElTableColumn>
            <ElTableColumn label="合计" prop="count_sum" />
        

          <!-- <template v-else>
            <ElTableColumn label="市场" prop="market_name"  label-class-name="market"/>
              <ElTableColumn label="门店" prop="shop_name" />
              <ElTableColumn label="员工姓名" prop="name" />
              <ElTableColumn label="岗位" prop="station_name" />

              <ElTableColumn label="跟台护士数据">
                <el-table-column v-for="(item, index) in title" :key="index"  :label="item.label"  >
                   <template slot-scope="{ row }">
                    <div class="hand" @click="clickOnline(row,row.title[index].label)">{{row.title[index].value}}</div>
                  </template>
                </el-table-column>
              </ElTableColumn>
              <ElTableColumn label="配台费" v-if="is_show_setUpfee">
              <el-table-column v-if ="is_show_setUpfee" v-for="(item, index) in setUpFee" :key="index"  :label="item.label">
                 <template slot-scope="{ row }">
                  <div class="hand" @click="clickOnline(row,row.setUpFee[index].label)">{{row.setUpFee[index].value}}</div>
                </template>
              </el-table-column>
            </ElTableColumn>

            <ElTableColumn label="合计" prop="count_sum" />
          </template> -->

            </el-table>
            
            <!-- <pagination
              class="page tc mt10"
              :total="page.total"
              :page.sync="page.page"
              :limit.sync="page.limit"
              @pagination="getList"
            /> -->
          </el-col>
        </el-row>
      </div>
    </div>
  </template>
  <script>
  import { getLastDay } from '@/utils';
  import pagination from "@/components/Pagination";
  import { shopDk1,getOutpatientService } from "@/api/statistics";
  class Page {
    total = 0;
    page = 1;
    limit = 10;
  }
  class Search {
    start_time = "";
    end_time = "";
    type = "";
  }
 
  export default {
    name: "OrderList",
    components: {
      pagination
    },
    data() {
      return {
        search: new Search(), // 检索条件
        page: new Page(), // 分页sss
        list: [], // 数据展示
        title: [], //线上进店
        setUpFee:[],//配台费
        loading:false,
        exportS: false,
        type:[],
        is_show_doctor:false,//是否显示医师数据
        is_show_setUpfee:false,//是否显示配台费
        showTable:false
        
      };
    },
    computed: {
        tableData() {
          const res = this.list.reduce((res, item) => {
            item.maxnum = 0;
            if (item.list && item.list.length > 0) {
              const goods = item.list.map(goods => Object.assign({}, item, goods));
              goods[0].maxnum = item.list.length;
              res = res.concat(goods);
            } else {
              res.push(item);
            }
            return res;
          }, []);
          return res;
        }
      },
    mounted() {
      let year = new Date().getFullYear()
      let month = new Date().getMonth() + 1
      if(month.toString().length == 1){
        month = '0'+ month
      }
      let day = getLastDay(year,month)
      if(day.toString().length == 1){
        day = '0'+ day
      }
      this.search.start_time = year+'-'+ month + '-01'
      this.search.end_time = year+'-'+ month + '-' + day
      this.getList('search');
  
    },
    methods: {
      // 获取列表
      getList(option) {
        this.is_show_doctor = true;
        this.loading = true
        if (option === "search") this.page = new Page();
        shopDk1({ ...this.search,export:0}).then(res => {
          this.is_show_doctor = res.data.is_show_doctor? res.data.is_show_doctor:false;
          this.is_show_setUpfee = res.data.is_show_setUpfee?res.data.is_show_setUpfee:false;
          this.showTable = true
          this.list = res.data.list;
          this.title = res.data.title;
          this.setUpFee = res.data.set_up_fee?res.data.set_up_fee:[];
          this.loading = false
   
        });
        
        getOutpatientService({ ...this.search,export:0}).then(res => {
          this.type = res.data
        });
      },
      objectSpanMethod({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 0) {
          return {
            rowspan: row.maxnum,
            colspan: 1
          };
        } else {
          return {
            rowspan: 1,
            colspan: 1
          };
        }
      },
      cellStyle({row, column, rowIndex, columnIndex}){
          if(columnIndex <= 1){
              return 'background:#CCFFFF;text-align:center'
          }
          if(columnIndex > 1 && columnIndex <= 13){
              return 'background:#FDE9D9;text-align:center'
          }
          if(columnIndex >= 14 && columnIndex <= 19){
              return 'background:#D8E4BC;text-align:center'
          }
          if(columnIndex >= 20){
              return 'background:#FFFF99;text-align:center'
          }
          // if(columnIndex = 21){
          //     return 'background:#FFFF99;text-align:center'
          // }
          // if(columnIndex = 22){
          //     return 'background:#FFFF99;text-align:center'
          // }
          return '';
        },
  
        rowClass({row, column, rowIndex, columnIndex}){
          return 'background:#E9EDEE;text-align:center'
  
        },
      // 导出
      exportExcel() {
        this.exportS = true
        shopDk({...this.search,export:1}).then(res => {
          window.open(res.data.url);
          this.exportS = false
        });
      },
      // 跳转事件
      clickOnline(row,id){
        console.log(row,id);
        this.$router.push("./OutpatientCareDetail?person_id="+row.id + '&type='+id  + '&start_time='+this.search.start_time + '&end_time='+this.search.end_time);
      },
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .hand{
    cursor:pointer;
  }
  .btnBox{
    text-align: right;
  }
  .market{
    background: red;
  }
  </style>
  